import React from "react";

import Seo from "src/components/seo";
import Layout from "src/components/layout/Layout";
import ContactFooter from "src/components/layout/ContactFooter/ContactFooter";
import PrivacyPolicyContent from "src/components/pages/privacy-policy/PrivacyPolicyContent";
import Patrons from "src/components/layout/Patrons/Patrons";

const index = () => {
  return (
    <Layout>
      <Seo />
      <div
        style={{
          width: "100vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <video controls style={{ width: "60%", height: "auto" }}>
          <source src={"/Hexar-demo.mp4"} type="video/mp4" />
        </video>
      </div>
      <ContactFooter showForm={true} showInfo={true} />
      <Patrons />
    </Layout>
  );
};

export default index;
